.mediaDiv {
    .buttonsTabs {
        padding-top: 8px;

        div {
            width: 40%;
            max-width: 164px;
            margin-right: 24px;
            padding: 12px 24px;
            font-size: 18px;
            background-color: #EBEFF2;
            color: #003362;
            border-radius: 12px;
            text-align: center;
            display: inline-block;
            cursor: pointer;

            &.active {
                background-color: #003362;
                color: white;
            }
        }
    }

    .pagesDiv {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 16px;

        .subtitle {
            font-weight: 700;
            font-size: 18px;
        }
    }

    .element {
        box-shadow: 0px 8px 24px rgba(33, 33, 33, 0.08);
        border-radius: 12px;
        padding: 18px 24px;
        margin-top: 24px;

        .icons {
            cursor: pointer;
        }

        .fa-bars {
            color: #FBCF13;
            font-size: 20px;
            margin-right: 12px;
            vertical-align: sub;
        }

        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .part {
                border-right: 1px solid #D6DEE6;
                padding: 0 24px;
                font-size: 14px;

                .fa-trash-alt {
                    color: #FF0000;
                    font-size: 16px;
                }

                &:last-of-type {
                    padding-right: 0;
                    border-right: none;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .mediaDiv {
        .element {
            .right {
                .part {
                    padding: 0 6px;
                }
            }
        }
    }
}